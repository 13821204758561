
:root {
  --bs-danger-rgb: 219, 59, 50; /* Example: Changing to pure red */
}

.websites__outlet {
  width: 100%;
}

button, input {
  border: 1px solid transparent;
}

button {
  background-color: transparent;
  padding: 0;
}

input, select, textarea, button {
  font-family: inherit;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.top-card-link__logo--link-icon {
  background-color: transparent;
}

.top-card-link__logo {
  margin-right: 8px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.top-card-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-card-link {
  font-size: 1.0rem;
  line-height: 1.25;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

button {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: rgb(225, 225, 225);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
}

h2, h3 {
  margin: 0;
}

section {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  line-height: 1.5;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

img {
  outline: 0;
}

div {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: block;
}

body, p {
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.top-card__profile-image-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -50px; /* Adjust as needed */
  z-index: 10; /* Ensure it stays above other elements */
}

.top-card-link__description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 2.4rem !important;
  max-height: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-card-link__logo {
  margin-right: 8px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.artdeco-entity-image--square-1 {
  width: 32px;
  height: 32px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: content-box;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 6px;
}

.artdeco-entity-image {
  display: inline-block;
}

.top-card-layout__entity-info--right-column {
  margin-left: 24px;
  max-width: 288px;
}

.top-card-layout__cta.top-card-layout__cta {
  margin: 0;
  background-color: #0073b1;
  border: 0;
  line-height: 4rem;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-size: .9rem;
  font-weight: 600;
  font-family: inherit;
  overflow: hidden;
  outline-width: 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition-duration: 167ms;
  transition-property: background-color, box-shadow, color, -webkit-box-shadow;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  vertical-align: middle;
  z-index: 0;
  color: #fff;
  height: auto;
}

.top-card-layout__cta-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-top: 10px;
}

.top-card-layout__first-subline {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}

.top-card-layout__headline {
  font-size: 1.6rem;
  line-height: 1.75;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.top-card-layout__title {
  font-size: 2.4rem;
  line-height: 1.33333;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
}

.top-card-layout__entity-info {
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
}

.top-card-layout__entity-info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.artdeco-entity-image--circle-8 {
  width: 128px;
  height: 128px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: content-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 49.9%;
}

.top-card-layout__entity-image {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-box-shadow 83ms;
  transition: -webkit-box-shadow 83ms;
  transition: box-shadow 83ms;
  transition: box-shadow 83ms, -webkit-box-shadow 83ms;
  position: relative;
}

.top-card__profile-image-container .top-card__profile-image--ghost-image {
  display: none;
}

.top-card-layout__entity-image {
  border: 4px solid #000;
  margin: -100px 0 12px;
}

.top-card-layout__card {
  padding: 24px;
  position: relative;
}

.top-card-layout {
  border-radius: 2px;
}

.cover-img {
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.top-card-layout__entity-image {
  border: 7px solid #000;
  margin: -100px 0 12px;
}

.top-card-layout__entity-image {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: -webkit-box-shadow 83ms;
  transition: -webkit-box-shadow 83ms;
  transition: box-shadow 83ms;
  transition: box-shadow 83ms, -webkit-box-shadow 83ms;
  position: relative;
}

.artdeco-entity-image--circle-8 {
  width: 128px;
  height: 128px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-clip: content-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 49.9%;
}

.top-card-layout .cover-img {
  border-radius: 2px 2px 0 0;
}

.top-card-layout .cover-img {
  height: 198px;
}

.lazy-loaded {
  opacity: 1;
}

.cover-img.lazy-loaded {
  background-image: url('/assets/img/Carbon-black.png');
}

.artdeco-entity-image {
  display: inline-block;
}

.top-card-layout--show-current-hide-past-position {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  display: block;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: box-shadow 83ms, -webkit-box-shadow 83ms;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

